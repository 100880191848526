
import CyanPageWrapper, {
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import { IonButton,IonIcon, IonPage } from "@ionic/vue";
import { megaphoneOutline, helpBuoyOutline, trophyOutline } from 'ionicons/icons';
import UserData from "@/components/UserData.vue";
import BotonDirectorioStreamlined from '@/components/BotonDirectorioStreamlined.vue';
export default defineComponent({
  name: "Home",
  components: {
    IonButton,
    IonIcon,
    IonPage,
    BotonDirectorioStreamlined,
    CyanPageWrapper,
    UserData,
  },
  setup() {
    return {
      megaphoneOutline,
      trophyOutline,
      helpBuoyOutline
    }
  },
  computed: {
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p('busl') && !p('jrsl') && !p('pcsl') && !p('dgsl');
    },
    numBotones() {
      let nb = 0;
      const p = (this as any).$store.getters.userHasPermission;

      for (const x of ['streamlined', 'carga', 'directorio', 'estructura', 'punto', 'agente']) {
        if (p(x)) nb++;
      }

      return nb;
    }
  },
  methods: {},
});
